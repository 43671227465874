import * as React from "react";
import Layout from "../../components/Layout";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";

const Colors = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "rgb(235, 10, 30)", height: "846px" }}
      >
        <div
          className="content-flex"
          style={{ color: "#fff", top: "", textAlign: "left" }}
        >
          <h1
            className="copy"
            style={{ color: "#fff", top: "", textAlign: "left" }}
          >
            Colour is a powerful means of recognition, helping establish a clear
            identity and distinction for Toyota and our products. At the heart
            of our brand is Toyota Red. Red is the colour of energy. It is
            highly visible and conveys the Toyota spirit of excitement.
          </h1>
        </div>
      </div>

      <section
        className="component Section"
        data-index="0"
        data-navindex="6"
        id="brand-colors"
      >
        <div className="section-header" data-tag="absent">
          <div className="container">
            <h2 className="ui header">Brand Colours</h2>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ background: "#fafafa" }}
          data-compid="brand-colors-0"
        >
          <div className="container twocolumn" id="">
            <h3 className="title" style={{ color: "#000" }}>
              Colour palette
            </h3>
            <div className="copy">
              Our colour palette is simple and impactful, comprising Toyota Red,
              white, black and gray. These are the colours of both our identity,
              as expressed through our logos, and our entire Visual Identity
              System. In addition to the colours inherent within photography,
              these are the only colours to be used in our brand communications.
              This concise palette of colours will help increase visual impact
              and overall brand recall.
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ background: "#EB0A1E" }}
          data-compid="brand-colors-1"
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#fff" }}>
              Toyota Red
            </h3>
            <div className="copy" style={{ color: "#fff" }}>
              PMS 186C
              <br />
              CMYK 00 100 90 00
              <br />
              RGB 235 10 30
              <br />
              HEX EB0A1E
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ background: "#fafafa" }}
          data-compid="brand-colors-2"
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#000" }}>
              White
            </h3>
            <div className="copy">
              CMYK 00 00 00 00
              <br />
              RGB 255 255 255
              <br />
              HEX FFFFFF
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ color: "#000" }}
          data-compid="brand-colors-3"
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#fff" }}>
              Black
            </h3>
            <div className="copy" style={{ color: "#fff" }}>
              PMS Black C<br />
              CMYK 00 00 00 100
              <br />
              RGB 00 00 00
              <br />
              HEX 000000
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ background: "#58595b" }}
          data-compid="brand-colors-4"
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#fff" }}>
              Gray
            </h3>
            <div className="copy" style={{ color: "#fff" }}>
              PMS Black C - 80% TINT
              <br />
              CMYK 00 00 00 80
              <br />
              RGB 88 89 91
              <br />
              HEX 58595B
            </div>
          </div>
        </div>
      </section>

      <section
        className="component Section"
        data-index="1"
        data-navindex="9"
        id="color-mode"
      >
        <div className="section-header" data-tag="absent">
          <div className="container">
            <h2 className="ui header">Colour Mode</h2>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ background: "#fafafa" }}
          data-compid="color-mode-0"
        >
          <div className="container twocolumn" id="">
            <h3 className="title" style={{ color: "#000" }}>
              Toyota Red
            </h3>
            <div className="copy">
              Toyota Red is the primary brand colour expressed throughout the
              Toyota Type family of logos. In marketing communications, it may
              also be used in typography and as a background.
            </div>
          </div>
        </div>
        <div
          className="image"
          style={{ background: "#fafafa" }}
          data-compid="color-mode-1"
        >
          <div className="full-width">
            <div className="image">
              <picture>
                <source
                  media="(max-width:767px)"
                  srcSet="/brandguidelines/imgix/color/color-use/colors_use_red_mobile.jpg"
                />
                <source
                  media="(min-width:768px) and (max-width:1024px)"
                  srcSet="/brandguidelines/imgix/color/color-use/colors_use_red_tablet.jpg"
                />
                <source
                  media="(min-width:1900px)"
                  srcSet="/brandguidelines/imgix/color/color-use/colors_use_red.jpg"
                />
                <img
                  alt=""
                  src="/brandguidelines/imgix/color/color-use/colors_use_red_desktop.jpg"
                  alt="National Clearance Event billboard"
                />
              </picture>
            </div>
          </div>
        </div>
        <div
          className="checkerboard"
          style={{ background: "#fff" }}
          data-compid="color-mode-2"
        >
          <div className="full-width">
            <ul>
              <li>
                <div className="wrap" style={{ background: "#fff" }}>
                  <div
                    className="left"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <span>
                        <div className="image">
                          <picture>
                            <source
                              media="(max-width:767px)"
                              srcSet="/brandguidelines/imgix/color/color-use/colors_use_white_mobile.jpg"
                            />
                            <source
                              media="(min-width:768px) and (max-width:1024px)"
                              srcSet="/brandguidelines/imgix/color/color-use/colors_use_white_tablet.jpg"
                            />
                            <source
                              media="(min-width:1900px)"
                              srcSet="/brandguidelines/imgix/color/color-use/colors_use_white.jpg"
                            />
                            <img
                              alt=""
                              src="/brandguidelines/imgix/color/color-use/colors_use_white_desktop.jpg"
                              alt="Toyota vehicle on white background on a laptop"
                            />
                          </picture>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    className="right"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <span></span>
                      <div className="copyWrap">
                        <h3 className="title">White</h3>
                        <p className="text">
                          White space allows for information to breathe and
                          often promotes greater visibility and impact.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="wrap" style={{ background: "transparent" }}>
                  <div
                    className="left"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <span>
                        <div className="image">
                          <picture>
                            <source
                              media="(max-width:767px)"
                              srcSet="/brandguidelines/imgix/color/color-use/colors_use_black_mobile.jpg"
                            />
                            <source
                              media="(min-width:768px) and (max-width:1024px)"
                              srcSet="/brandguidelines/imgix/color/color-use/colors_use_black_tablet.jpg"
                            />
                            <source
                              media="(min-width:1900px)"
                              srcSet="/brandguidelines/imgix/color/color-use/colors_use_black.jpg"
                            />
                            <img
                              alt=""
                              src="/brandguidelines/imgix/color/color-use/colors_use_black_desktop.jpg"
                              alt="Toyota vehicle with black text on a smartphone"
                            />
                          </picture>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    className="right"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <span></span>
                      <div className="copyWrap">
                        <h3 className="title">Black</h3>
                        <p className="text">
                          Black is generally used only for typography, but may
                          be used in other select and appropriate situations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="wrap" style={{ background: "transparent" }}>
                  <div
                    className="left"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <span>
                        <div className="image">
                          <picture>
                            <source
                              media="(min-width:768px) and (max-width:1024px)"
                              srcSet="/brandguidelines/imgix/color/color-use/colors_use_gray_tablet.jpg"
                            />
                            <source
                              media="(min-width:1900px)"
                              srcSet="/brandguidelines/imgix/color/color-use/colors_use_gray.jpg"
                            />
                            <img
                              alt=""
                              src="/brandguidelines/imgix/color/color-use/colors_use_gray_desktop.jpg"
                              alt="Layout with black text over gray background"
                            />
                          </picture>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    className="right"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <span></span>
                      <div className="copyWrap">
                        <h3 className="title">Gray</h3>
                        <p className="text">
                          Gray may be used as a secondary colour in headline and
                          body text when sufficient contrast can be maintained
                          for legibility.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ background: "#fafafa" }}
          data-compid="color-mode-3"
        >
          <div className="container twocolumn" id="">
            <h3 className="title" style={{ color: "#000" }}>
              Selecting the correct colour mode
            </h3>
            <div className="copy">
              Colour specifications are different across various media
              applications, therefore it is important to use the correct colour
              mode for the specific media application to achieve the intended
              colour values.
            </div>
          </div>
        </div>
        <div
          className="fiftyfifty"
          style={{ background: "#fafafa" }}
          data-compid="color-mode-4"
        >
          <div className="full-width">
            <div
              className="col"
              style={{ background: "transparent", padding: "0px" }}
            >
              <div className="block">
                <span>
                  <div className="image">
                    <div
                      style={{ height: "100%" }}
                      className="lazyload-placeholder"
                    ></div>
                  </div>
                  <div className="caption-container bottom-center">
                    <div className="caption">
                      Website and Microsoft applications use RGB colour mode.
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div
              className="col"
              style={{ background: "transparent", padding: "0px" }}
            >
              <div className="block">
                <span>
                  <div className="image">
                    <div
                      style={{ height: "100%" }}
                      className="lazyload-placeholder"
                    ></div>
                  </div>
                  <div className="caption-container bottom-center">
                    <div className="caption">
                      Video applications use HEX colour mode.
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="fiftyfifty"
          style={{ background: "#fafafa" }}
          data-compid="color-mode-5"
        >
          <div className="full-width">
            <div
              className="col"
              style={{ background: "transparent", padding: "0px" }}
            >
              <div className="block">
                <span>
                  <div className="image">
                    <div
                      style={{ height: "100%" }}
                      className="lazyload-placeholder"
                    ></div>
                  </div>
                  <div className="caption-container bottom-center">
                    <div className="caption">
                      Digital printing from Adobe CS programs uses CMYK colour
                      mode.
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div
              className="col"
              style={{ background: "transparent", padding: "0px" }}
            >
              <div className="block">
                <span>
                  <div className="image">
                    <div
                      style={{ height: "100%" }}
                      className="lazyload-placeholder"
                    ></div>
                  </div>
                  <div className="caption-container bottom-center">
                    <div className="caption">
                      Spot printing, screen printing and embroidery use Pantone®
                      colour modes.
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ background: "#fafafa" }}
          data-compid="color-mode-6"
        >
          <div className="container twocolumn" id="">
            <div className="copy">
              <strong>Note:</strong> Colours will not match exactly across
              various media applications. This is due to the differences between
              the displaying media and type of reproduction. For instance,
              colours will appear more vibrant in digital than on paper because
              digital is a backlit surface, as opposed to paper, which is an
              opaque surface. Even within the same media, applications may
              appear slightly different. For example, computer screens may be
              calibrated differently, and paper materials have different hues
              and levels of opacity.
            </div>
          </div>
        </div>
      </section>

      <div>
        <a
          className="up-next"
          href="/brandguidelines/layout/"
          target="_self"
          data-label="Layout"
        >
          <div className="link-section">
            <h4 className="ui header next">Next</h4>
            <h1 className="ui header section-name">Layout</h1>
            <i aria-hidden="true" className="icon">
              <svg width="20" height="14" viewBox="0 0 19 12">
                <g fill="none" fillRule="evenodd" strokeWidth="2">
                  <path strokeLinecap="square" d="M1.05 5.84h14.306"></path>
                  <path d="M12.008 11.023L16.82 5.84 12.008.657"></path>
                </g>
              </svg>
            </i>
          </div>
        </a>
      </div>

      <div className="page-footer">
        <div className="container">
          <div className="copyright">
            ©2021 Toyota Motor Sales, U.S.A., Inc. All information applies to
            U.S. vehicles only.
          </div>
          <div className="footer-links">
            <a
              href="https://www.toyota.com/support/privacy-rights"
              className="links"
            >
              Privacy Policy
            </a>
            |
            <a
              href="https://www.toyota.com/support/legal-terms"
              className="links"
            >
              Legal Terms
            </a>
          </div>
          <div
            style={{
              clear: "both",
              textAlign: "center",
              lineHeight: 1.75,
              paddingTop: "10px",
            }}
          >
            Vehicles represented on this site are for composition purposes only.
            The vehicles do not necessarily represent current model-year options
            or specifications. Please see your local dealer or toyota.com for
            product offerings.
          </div>
        </div>
      </div>

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Typography"
        previousUrl="/brandguidelines/typography"
        nextText="Next"
        nextHeader="TVC Endframe"
        nextUrl="/brandguidelines/tvc-endframe"
      />
    </Layout>
  );
};

export default Colors;
